import React from 'react'
import PropTypes from 'prop-types'
import ContactRecipientTable from '@components/contactrecipienttable/index'

export const frontmatter = {
  title: 'Kontakt - E-Mail Einstellung',
}

function ContactRecipientPage() {
  return <ContactRecipientTable />
}

ContactRecipientPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default ContactRecipientPage
